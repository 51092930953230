import React from "react";
import clsx from "clsx";
import { Heading, IconCopy } from "@molecules";
import { Container } from "@atoms";

const IconCopyGrid = ({
  heading,
  description,
  columns,
  className: _className,
}) => {
  return (
    <section className={clsx("relative", _className)}>
      <Container>
        <Heading heading={heading} description={description} size="longH3" />
        <div
          className={clsx(
            "mt-8 grid grid-cols-1 gap-10 sm:mt-16 sm:grid-cols-2 sm:px-12 md:grid-cols-3"
            // { "mt-8 sm:mt-16": heading }
          )}
        >
          {columns?.map(column => {
            return <IconCopy {...column} />;
          })}
        </div>
      </Container>
    </section>
  );
};

export default IconCopyGrid;
